import React, { useEffect } from "react";
import { useParams } from "react-router";
import NewProductPage from "./product/NewProductPage";
import NewNewProductPage from "./product/NewNewProductPage";

const CONFIG = {
  cz: {
    title: "Zjisti více",
    description:
      "Uč se přes reálné životní situace podpořené vhledem rodilých mluvčích.",
  },
  cs: {
    title: "Zjisti více",
    description:
      "Uč se přes reálné životní situace podpořené vhledem rodilých mluvčích.",
  },
  hu: {
    title: "Tudj meg többet",
    description:
      "Tanulj valós élethelyzeteken keresztül, anyanyelvi beszélők betekintéseivel támogatva.",
  },
  en: {
    title: "Learn more",
    description:
      "Learn through real-life situations, supported by native speaker insights.",
  },
  sk: {
    title: "Zistiť viac",
    description:
      "Učte sa prostredníctvom reálnych životných situácií s podporou náhľadov rodených hovorcov.",
  },
  lt: {
    title: "Sužinok daugiau",
    description:
      "Mokykis realiose gyvenimo situacijose, remiantis įžvalgomis iš gimtakalbių.",
  },
  hr: {
    title: "Saznaj više",
    description: "Uči kroz životne situacije, uz uvide izvornih govornika.",
  },
  ro: {
    title: "Aflați mai multe",
    description:
      "Învață din situații din viața reală, sprijinite de înțelegerile vorbitorilor nativi.",
  },
  lv: {
    title: "Sužinokite daugiau",
    description:
      "Mokykitės per tikro gyvenimo situacijas, palaikomas gimtosios kalbos kalbėtojų įžvalgų.",
  },
  bg: {
    title: "Научете повече",
    description:
      "Учете чрез реални житейски ситуации, подкрепени от прозрения на носители на езика.",
  },
};

const ProductPage = ({ data }) => {
  const { lang } = useParams();

  useEffect(() => {
    document.title = CONFIG[lang]?.title;
    document.getElementsByName("description")[0].content =
      CONFIG[lang]?.description;
  }, [lang]);

  if (
    ["cs", "bg", "hr", "cz", "en", "ro", "sk", "lt", "lv", "hu", "pl"].includes(
      data?.product_page?.language
    )
  ) {
    return <NewNewProductPage data={data.product_page} />;
  }

  return (
    <div>
      <NewProductPage data={data.product_page} />
    </div>
  );
};

export default ProductPage;
