import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import screen1 from "../images/newProductPage/english/1.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const polish = {
  navbar: {
    data: [
      { title: "Zamów kurs", path: "/course" },
      { title: "Zaloguj się", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "O nas", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "pl",
      hero: {
        languages: [
          "po angielsku",
          "po hiszpańsku",
          "po niemiecku",
          "po holendersku",
          "po włosku",
          "po rosyjsku",
          "po polsku",
          "po francusku",
          "po portugalsku",
        ],
        title: "Mów pewniej,",
        prefix: "odważniej",
        subtitle:
          "Zdobądź umiejętności językowe dzięki naszemu kursowi opartemu na realnych przykładach!",
        buttonText: "Zamów teraz!",
      },
      learning: {
        title: "Jak wygląda kurs?",
        config: [
          {
            image: screen1,
            category: "Ucz się języka w najprostszy sposób",
            descriptions: [
              {
                bold: "Dziesiątki lekcji",
                rest: "z przykładami opartymi na codziennej komunikacji.",
              },
              {
                bold: "Praktyczne frazy",
                rest: "zaprojektowane przez native speakerów.",
              },
              {
                bold: "Ćwiczenia",
                rest: "które skutecznie rozwijają umiejętności czytania, słuchania i mówienia.",
              },
            ],
          },
          {
            image: screen1,
            category: "Praktyczne lekcje",
            description:
              "Każdy poziom nauki składa się z dziesiątek lekcji dopasowanych do Twoich potrzeb.",
          },
          {
            image: screen1,
            category: "Zadania",
            description:
              "Nasze ćwiczenia skutecznie wspierają powtarzanie materiału i poprawiają pamięć.",
          },
          {
            image: screen1,
            category: "Słowa, frazy i wyrażenia",
            description:
              "Każda lekcja zawiera starannie dobrane obrazy, które sprawiają, że proces nauki jest intuicyjny.",
          },
        ],
        photoData: [screen1],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angielski" },
          { image: new_french, lang: "Francuski" },
          { image: new_spanish, lang: "Hiszpański" },
          { image: new_niderland, lang: "Holenderski" },
          { image: new_germany, lang: "Niemiecki" },
          { image: new_italy, lang: "Włoski" },
          { image: new_russia, lang: "Rosyjski" },
          { image: new_portugal, lang: "Portugalski" },
          { image: new_polish, lang: "Polski" },
        ],
        title: "Dostępne języki:",
        description:
          "Przygotuj się na kolejną podróż lub dodaj umiejętności językowe do swojego CV.",
      },
      expertSection: {
        title: "Twój ekspert językowy",
        firstSectionTitle: "Wsparcie na wyciągnięcie ręki",
        firstSectionDescription: "Otrzymuj pełne wsparcie podczas nauki!",
        secondSectionTitle: "Zadaj pytania i otrzymaj odpowiedzi",
        secondSectionDescription:
          "Możesz zadać pytanie bezpośrednio swojemu ekspertowi.",
        thirdSectionTitle: "Indywidualne podejście na każdym kroku",
        thirdSectionDescription:
          "Korzystanie z porad eksperta przyspiesza proces nauki.",
      },
      courseValue: {
        title: "Zyskaj więcej z eLingo",
        subtitle: "Nie tylko nauka języka – zyskaj pełny dostęp do narzędzi.",
        buttonText: "Zamów teraz!",
        config: [
          {
            title: "Nauka dostosowana do Twojego stylu życia",
            sections: [
              {
                text: "Ucz się gdziekolwiek i kiedykolwiek chcesz, dzięki naszej platformie.",
              },
              {
                text: "Krótkie lekcje idealnie wpasowują się w Twój codzienny plan.",
              },
            ],
          },
          {
            title: "Motywacja na każdym kroku",
            sections: [
              {
                text: "Kontynuuj swoją serię dzięki naszemu systemowi śledzenia.",
              },
              {
                text: "Każdy dzień nauki przybliża Cię do płynności.",
              },
            ],
          },
          {
            title: "Pełna elastyczność i personalizacja",
            sections: [
              {
                text: "Dostosuj kurs do swojego poziomu i tempa nauki.",
              },
              {
                text: "Skup się na tym, co Cię najbardziej interesuje.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Misja",
      description: `Wierzymy, że język stanowi most między kulturami, a naszą misją jest przezwyciężanie barier komunikacyjnych i zbliżanie ludzi poprzez edukację językową.`,
    },
    why: {
      title: "Dlaczego my?",
      config: [
        {
          title: "Innowacyjne metody edukacyjne",
          image: dlaczego1,
          description:
            "Nasz zaawansowany i sprawdzony w praktyce algorytm nauczania dostosowuje materiały do Twojego obecnego poziomu wiedzy i rozwija go poprzez efektywne nauczanie.",
        },
        {
          title: "Szeroki wybór języków",
          image: dlaczego2,
          description:
            "Oferujemy kursy w najpopularniejszych językach świata. Dzięki temu możesz wybrać język, który odpowiada Twoim potrzebom.",
        },
        {
          title: "Edukacja w przyjemny sposób",
          image: dlaczego3,
          description:
            "Nasza platforma sprawia, że nauka języków jest zabawna i przyjemna, co motywuje do dalszej nauki.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalizacja i dostępność",
          description:
            "Nasz wydajny algorytm uczenia maszynowego nieustannie dostarcza materiały na odpowiednim poziomie dla każdego użytkownika. eLingo umożliwia odkrywanie wzorców samodzielnie, bez konieczności skupiania się na zasadach języka – tak jak nauczyłeś się swojego ojczystego języka w dzieciństwie.",
          image: zdjecie1,
        },
        {
          title: "Połączenie technologii i nauki",
          description:
            "eLingo łączy technologię z nauką, aby pobudzić umysł do działania. Powtarzanie niezliczonych list słów i wyrażeń jest nudne, a Twój mózg o tym wie. Nasza metoda jest ekscytująca i skuteczna, zmuszając Cię do użycia zdobytej wiedzy w różny sposób w odpowiednich momentach – Twój mózg doceni to rozwiązanie!",
          image: zdjecie2,
        },
        {
          title: "Społeczność i wartości",
          description:
            "Języki pomagają ludziom angażować się. Nauka języków ułatwia zrozumienie. Społeczność eLingo łączy ludzi z całego świata. Jesteśmy ambitni i zawsze przekraczamy granice. Wierzymy, że nauka języków może być przyjemna i chętnie odkrywamy nowe kultury poprzez język.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Zainteresowany kursem? ",
      title1: "Chcesz uzyskać więcej informacji?",
      subtitle: "Podaj swój numer telefonu - oddzwonimy do Ciebie.",
      subtitle1: "Jeśli masz już konto, wypełnij poniższe pola:",
      button1: "Imię i Nazwisko",
      button2: "Numer telefonu",
      button3: "Email",
      buttonSub: "Wyślij",
      checkOne:
        "Wysyłając ten formularz, akceptuję warunki i oświadczam, że zapoznałem się z",
      checkOneTwo: "następującymi",
      checkTwo: "Wyrażam zgodę na kontakt w celach marketingowych",
      textOne: "Informujemy, że",
      textTwo:
        "Administratorem Twoich danych osobowych jest EOU Sp. z o.o., z siedzibą w Warszawie, Młynarska 42 / 115, 01-171, zarejestrowana w rejestrze przedsiębiorców prowadzonym przez sąd rejonowy Warszawy pod numerem KRS5273057993, REGON: 525396900, NIP5273057993. Twoje dane osobowe będą przetwarzane w związku z zapytaniem z formularza kontaktowego lub realizacją zawartej umowy. Kontaktując się z nami, dobrowolnie wyrażasz zgodę na przetwarzanie swoich danych osobowych w wyżej wymienionych celach. Masz prawo dostępu do swoich danych, ich poprawiania, usuwania lub ograniczenia przetwarzania, prawo do sprzeciwu wobec przetwarzania oraz prawo do przenoszenia danych. Masz również prawo do wycofania zgody w dowolnym momencie, jednak wycofanie zgody nie wpływa na zgodność z prawem przetwarzania opartego na zgodzie przed jej wycofaniem. Masz także prawo złożyć skargę do organu nadzorczego. Podanie danych jest dobrowolne, ale może być niezbędne do odpowiedzi na zapytanie lub realizacji umowy. Dane osobowe będą przechowywane przez okres niezbędny do wyżej wymienionych celów, ale nie dłużej niż okres przedawnienia roszczeń wynikających z przepisów prawa. Prosimy o dokładne zapoznanie się z polityką prywatności",
      buttonMore: "Czytaj więcej",
      buttonLess: "Czytaj mniej",
      subOne: "warunkami",
      subTwo: "i zasadami prywatności.",
      buttonTXT: "Dziękujemy",
      rulesLink: "/Regulations_PL.pdf",
      privacyLink: "/Privacy_PL.pdf",
      product: "ep",
      market: "pl",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platforma_eLingo",
      product_variant: "pl-en",
      server_link: "https://cms.eou-assets.eu/integration/lead-from-landing",
      currency: "zł",
      price: 87,
    },
  },
  footer: {
    privacy: "Prywatność",
    privacyLink: "/Privacy_PL.pdf",
    rules: "Regulamin",
    rulesLink: "/Regulations_PL.pdf",
  },
};
