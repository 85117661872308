import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import phone from "../../../../common/assets/icon_white_phone.png";
import checkbox from "./images/checkbox.svg";
import manWithBook from "./images/man_with_book.png";
import Store from "../../../../Store";
import { UTM } from "../../../constants/utm";

const Container = styled.div`
  height: calc(100vh - 125px);
  overflow: hidden;
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 2;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const BackgroundDiv = styled.div`
  position: fixed;
  overflow: hidden;
  height: 230vw;
  width: 500vw;
  left: calc(100% - 50vw);
  top: 50%;
  transform: translateY(-50%);
  z-index: -2;
  background: transparent;

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--light-main-color);
    border-radius: 60%;
    z-index: 0;
  }
`;

const StyledInput = styled.input`
  border: none;
  border-radius: 5px;
  padding: 10px 0;
  width: 328px;
  font-size: 18px;
  text-align: center;
  background: var(--white-color);
  &::placeholder {
    color: var(--main-color);
  }

  @media (max-width: 1100px) {
    width: 224px;
  }

  @media (max-width: 900px) {
    width: 280px;
    background-color: var(--lighter-main-color);
  }
`;

const HeadingWrapper = styled.div`
  top: 80px;
  position: sticky;

  @media (max-width: 900px) {
    position: static;
  }
`;

const Heading = styled.h3`
  font-weight: 400 !important;
  text-align: left;
  margin-bottom: 20px;
  color: var(--main-color);
  font-weight: 700;
  font-size: 47px !important;

  @media (max-width: 900px) {
    font-size: 40px !important;
  }
`;

const HeadingBold = styled(Heading)`
  font-weight: 900 !important;
`;

const FormHeading = styled.h3`
  font-weight: 800 !important;
  text-align: left;
  margin-bottom: 12px;
  color: #fff;
  font-weight: 700;
  font-size: 20px !important;
  text-align: center;

  @media (max-width: 900px) {
    color: var(--main-color);
  }
`;

const FormSubHeading = styled.p`
  color: white;
  text-align: center;
  margin-bottom: 28px;

  @media (max-width: 900px) {
    color: var(--main-color);
  }
`;

const SubmitButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 328px;
  gap: 5px;
  font-size: 24px;
  padding: 12px 38px;
  border-radius: 9999px;
  font-size: 3rem;
  color: white;
  background-color: var(--main-color);
  font-weight: 700;
  margin: 28px 0;
  cursor: pointer;

  @media (max-width: 1100px) {
    font-size: 18px;
    padding: 10px 20px;
    width: 224px;
  }

  @media (max-width: 900px) {
    width: 280px;
  }
`;

const Form = styled.form`
  justify-content: center;
  align-items: center;
  max-width: 430px;
  justify-self: flex-end;
  padding: 20px;
  background: transparent;

  @media (max-width: 1100px) {
    max-width: 320px;
  }
`;

const SideWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background: transparent;

  @media (max-width: 900px) {
    max-height: fit-content;
    min-height: calc(40vh + 200px);
  }
`;

const ManWithBookWrapper = styled.div`
  position: absolute;
  z-index: -1;
  height: 500px;
  bottom: 0;
  right: 70%;

  @media (max-width: 900px) {
    position: static;
    height: 400px;
  }
`;

const ManImage = styled.img`
  height: 100%;
  max-height: 60vh;
  position: absolute;
  bottom: 0;

  @media (max-width: 900px) {
    height: fit-content;
    max-height: fit-content;
    position: static;
  }
`;

const RulesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const Rules = styled.p`
  color: white;
  margin-bottom: 14px;
  font-size: 14px !important;
  @media (max-width: 900px) {
    color: ${Colors.mainColor};
  }
`;

const RulesLink = styled.a`
  color: white;
  text-decoration: underline;
  @media (max-width: 900px) {
    color: ${Colors.mainColor};
  }
`;

const CheckBox = styled.input`
  display: none;
`;

const CustomCheckBox = styled.p`
  width: 21px;
  height: 21px;
  border-radius: 6px;
  flex-shrink: 0;
  outline: none;
  cursor: pointer;
  border: 1px solid white;

  @media (max-width: 900px) {
    border: 1px solid var(--main-color);
  }
`;

const CheckedCustomCheckBox = styled.img`
  width: 21px;
  height: 21px;
  border: 1px solid var(--main-color);
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
`;

const MoreTextWrapper = styled.div`
  color: white;

  @media (max-width: 900px) {
    color: ${Colors.mainColor};
  }
`;

const MoreTextButton = styled.button`
  color: white;
  border: none;
  background: none;
  font-size: 10px;
  cursor: pointer;

  @media (max-width: 900px) {
    color: ${Colors.mainColor};
  }
`;

const FormSender = ({ data }) => {
  const [text, setText] = useState("...");
  const [showMore, setShowMore] = useState(false);
  const [showButtonThx, setShowButtonThx] = useState(true);
  const [checkedA, setCheckedA] = useState(false);
  const [checkedB, setCheckedB] = useState(false);

  const inputOne = useRef();
  const inputTwo = useRef();
  const inputThree = useRef();

  const changeText = (e) => {
    setShowMore(() => !showMore);
    e.preventDefault();
    if (text === "...") {
      setText(data.textTwo);
    } else {
      setText("...");
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    window.gtag("event", "form_submit");

    const dataOne = inputOne.current.value;
    const dataTwo = inputTwo.current.value;
    const dataThree = inputThree.current.value;

    inputOne.current.value = "";
    inputTwo.current.value = "";
    inputThree.current.value = "";
    setShowButtonThx(false);

    setTimeout(() => {
      setShowButtonThx(true);
    }, 6000);

    await axios
      .post(data.server_link, {
        phone: dataTwo,
        name: dataOne,
        email: dataThree,
        surname: "",
        utm_source: Store.getItem(UTM.SOURCE) || data.utm_source,
        utm_medium: Store.getItem(UTM.MEDIUM) || data.utm_medium,
        market_short: data.market,
        send_to_url: data.send_to_url,
        price: data.price,
        currency: data.currency,
        product_short: data.product,
        product_variant: data.product_variant,
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {}, [
    text,
    setText,
    showMore,
    setShowMore,
    showButtonThx,
    setShowButtonThx,
  ]);

  return (
    <>
      <BackgroundDiv />
      <Container>
        <Wrapper>
          <SideWrapper>
            <HeadingWrapper>
              <HeadingBold id="formHeading">{data.title}</HeadingBold>
              <Heading id="formHeading">{data.title1}</Heading>
            </HeadingWrapper>
            <ManWithBookWrapper>
              <ManImage alt="man with book" src={manWithBook} />
            </ManWithBookWrapper>
          </SideWrapper>
          <Form id="form" style={{}} onSubmit={(e) => submitForm(e)}>
            <FormHeading id="formHeading">{data.subtitle}</FormHeading>
            <FormSubHeading>{data.subtitle1}</FormSubHeading>
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "center",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              <div
                className="input"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <StyledInput
                  ref={inputOne}
                  name="name"
                  placeholder={data.button1}
                  type="text"
                  required
                />
              </div>
              <div
                className="input"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <StyledInput
                  ref={inputTwo}
                  name="phone"
                  placeholder={data.button2}
                  type="text"
                  required
                />
              </div>
              <div
                className="input"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <StyledInput
                  ref={inputThree}
                  name="email"
                  placeholder={data.button3}
                  type="email"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {showButtonThx ? (
                <SubmitButton type="submit">
                  {data.buttonSub}{" "}
                  <img
                    alt="phone"
                    src={phone}
                    style={{ width: "20px", height: "20px" }}
                  />
                </SubmitButton>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "20px",
                    color: Colors.mainColor,
                  }}
                >
                  <SubmitButton>{data.buttonTXT}</SubmitButton>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}>{data.buttonText}</h3>
                    <h3 style={{ textAlign: "center" }}>{data.buttonText2}</h3>
                  </div>
                </div>
              )}
            </div>
            <label>
              <RulesWrapper>
                <CheckBox
                  onChange={(e) => setCheckedA(e.target.checked)}
                  type="checkbox"
                  required
                />
                {checkedA ? (
                  <CheckedCustomCheckBox alt="checkbox" src={checkbox} />
                ) : (
                  <CustomCheckBox />
                )}
                <Rules>
                  {data.checkOne}{" "}
                  <RulesLink
                    href={data.rulesLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.subOne}
                  </RulesLink>{" "}
                  <RulesLink
                    href={data.privacyLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.subTwo}
                  </RulesLink>
                </Rules>
              </RulesWrapper>
            </label>
            <label>
              <RulesWrapper>
                <CheckBox
                  onChange={(e) => setCheckedB(e.target.checked)}
                  type="checkbox"
                  required
                />
                {checkedB ? (
                  <CheckedCustomCheckBox alt="checkbox" src={checkbox} />
                ) : (
                  <CustomCheckBox />
                )}
                <Rules>{data.checkTwo}</Rules>
              </RulesWrapper>
            </label>
            <MoreTextWrapper
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "10px",
              }}
            >
              <p
                style={{
                  padding: "10px",
                  paddingBottom: "0px",
                  fontSize: "10px",
                }}
              >
                {data.textOne}: {text}
              </p>{" "}
              <MoreTextButton onClick={(e) => changeText(e)}>
                <div style={{ marginTop: "11px" }}>
                  {showMore ? data.buttonLess : data.buttonMore}
                </div>
              </MoreTextButton>
            </MoreTextWrapper>
          </Form>
        </Wrapper>
      </Container>
    </>
  );
};

export default FormSender;
