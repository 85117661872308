import React, { useContext } from "react";
import RightImage from "./images/zdjecie_hamak-kolo_desktop.png";
import styled from "styled-components";
import GreenFrame from "./images/green-frame.png";
import { Link, useParams } from "react-router-dom";

const Title = styled.div`
  color: #1e2d6a;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`;

const Description = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButNowButton = styled(Link)`
  display: inline-flex;
  padding: 11px 50px 15px 51px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #97ad45;

  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
  margin: 30px 0;
`;

const InfoTitle = styled.div`
  color: #1e2d6a;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 22px 0;

  @media (max-width: 600px) {
    text-align: center;
    width: 100%;
    font-size: 20px;
  }
`;

const InfoDescription = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 540px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Point = styled.div`
  min-height: 6px;
  min-width: 6px;
  max-width: 6px;
  max-height: 6px;

  background-color: black;
  border-radius: 50%;
  margin-top: 6px;
  margin-right: 6px;
`;

const InfoInsideWrapper = styled.div`
  display: flex;
  margin-left: 26px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;
`;

const ImageWrapper = styled.div`
  @media (max-width: 600px) {
    img {
      width: 254px;
      height: 254px;
    }
  }
`;

const GreenFrameWrapper = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const CourseValue = ({ data }) => {
  const { lang } = useParams();

  return (
    <div style={{ padding: "60px 0 60px 0" }}>
      <Title>{data.title}</Title>
      <Description>{data.subtitle}</Description>
      <InfoWrapper>
        <div>
          {data?.config?.map((c) => (
            <div>
              <TitleWrapper>
                <GreenFrameWrapper>
                  <img src={GreenFrame} />
                </GreenFrameWrapper>
                <InfoTitle>{c.title}</InfoTitle>
              </TitleWrapper>
              {c.sections.map((s) => (
                <InfoInsideWrapper>
                  <Point />
                  <InfoDescription>{s.text}</InfoDescription>
                </InfoInsideWrapper>
              ))}
            </div>
          ))}
        </div>
        <ImageWrapper>
          <img src={RightImage} />
        </ImageWrapper>
      </InfoWrapper>
      <ButtonWrapper>
        <ButNowButton to={`/${lang}/course`}>{data.buttonText}</ButNowButton>
      </ButtonWrapper>
    </div>
  );
};

export default CourseValue;
