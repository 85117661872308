import new_LTgland from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import screen1 from "../images/newProductPage/lithuanian/1.png";
import screen2 from "../images/newProductPage/lithuanian/2.png";
import screen3 from "../images/newProductPage/lithuanian/3.png";
import screen4 from "../images/newProductPage/lithuanian/4.png";
import screen5 from "../images/newProductPage/lithuanian/5.png";
import screen6 from "../images/newProductPage/lithuanian/6.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const lithuanian = {
  navbar: {
    data: [
      { title: "Užsisakyti kursą", path: "/course" },
      { title: "Prisijungti", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "Apie mus", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "lt",
      hero: {
        languages: [
          "anglų kalba",
          "ispanų kalba",
          "vokiečių kalba",
          "olandų kalba",
          "italų kalba",
          "rusų kalba",
          "lenkų kalba",
          "prancūzų kalba",
          "portugalų kalba",
        ],
        title: "Runājiet drosmīgi,",
        prefix: "ar pārliecību",
        subtitle:
          "Įgykite kalbos įgūdžių per mūsų kursą, paremtą tikrais gyvenimo pavyzdžiais!",
        buttonText: "Užsisakyti dabar!",
      },
      learning: {
        title: "Kaip atrodo kursas?",
        config: [
          {
            image: screen1,
            category: "Išmokite kalbą lengviausiu būdu",
            descriptions: [
              {
                bold: "Dešimtys pamokų",
                rest: "su pavyzdžiais, pagrįstais kasdieniu bendravimu.",
              },
              {
                bold: "Praktiškos frazės",
                rest: "sukurtos gimtakalbių kalbėtojų.",
              },
              {
                bold: "Pratimai,",
                rest: "kurie efektyviai lavina skaitymo, klausymo ir kalbėjimo įgūdžius.",
              },
            ],
          },
          {
            image: screen2,
            category: "Praktinės pamokos",
            description:
              "Kiekviename mokymosi lygyje yra dešimtys pamokų, pritaikytų jūsų poreikiams.",
          },
          {
            image: screen3,
            category: "Užduotys",
            description:
              "Mūsų pratimai efektyviai padeda kartoti medžiagą ir gerinti atmintį.",
          },
          {
            image: screen4,
            category: "Žodžiai, frazės ir išraiškos",
            description:
              "Kiekviena pamoka apima kruopščiai atrinktus vaizdus, kurie daro mokymosi procesą intuityvų.",
          },
        ],
        photoData: [screen1, screen2, screen3, screen4, screen5, screen6],
      },
      languages: {
        languages: [
          { image: new_LTgland, lang: "Anglų" },
          { image: new_french, lang: "Prancūzų" },
          { image: new_spanish, lang: "Ispanų" },
          { image: new_niderland, lang: "Olandų" },
          { image: new_germany, lang: "Vokiečių" },
          { image: new_italy, lang: "Italų" },
          { image: new_russia, lang: "Rusų" },
          { image: new_portugal, lang: "Portugalų" },
          { image: new_polish, lang: "Lenkų" },
        ],
        title: "Prieinamos kalbos:",
        description:
          "Pasiruoškite savo kitai kelionei arba pridėkite kalbos įgūdžių į savo CV.",
      },
      expertSection: {
        title: "Jūsų kalbos ekspertas",
        firstSectionTitle: "Pagalba pasiekiama ranka",
        firstSectionDescription: "Gaukite visą palaikymą mokymosi metu!",
        secondSectionTitle: "Užduokite klausimus ir gaukite atsakymus",
        secondSectionDescription:
          "Bet kokius klausimus galite užduoti tiesiogiai savo ekspertui.",
        thirdSectionTitle: "Individualus požiūris kiekviename žingsnyje",
        thirdSectionDescription:
          "Eksperto patarimų naudojimas pagreitina jūsų mokymąsi.",
      },
      courseValue: {
        title: "Gaukite daugiau su eLingo",
        subtitle:
          "Ne tik kalbos mokymasis – gaukite visą prieigą prie įrankių.",
        buttonText: "Užsisakyti dabar!",
        config: [
          {
            title: "Mokymasis, pritaikytas jūsų gyvenimo būdui",
            sections: [
              {
                text: "Mokykitės, kur norite ir kada norite, naudodamiesi mūsų platforma.",
              },
              {
                text: "Trumpi pamokos puikiai įsilieja į jūsų kasdienį tvarkaraštį.",
              },
            ],
          },
          {
            title: "Motyvacija kiekviename žingsnyje",
            sections: [
              {
                text: "Išlaikykite savo mokymosi tempą naudodami mūsų sekimo sistemą.",
              },
              {
                text: "Kiekviena mokymosi diena priartina jus prie kalbos laisvumo.",
              },
            ],
          },
          {
            title: "Visiškas lankstumas ir personalizavimas",
            sections: [
              {
                text: "Prisitaikykite kursą pagal savo lygį ir mokymosi tempą.",
              },
              {
                text: "Sutelkkite dėmesį į tai, kas jus labiausiai domina.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Misija",
      description: `Mūsų pagrindinis tikslas „eLingo“ yra kiekvienam žmogui suteikti galimybę turėti asmeninį mokytoją naudojantis technologijomis. Tikime, kad kalba – tiltas tarp kultūrų, ir mūsų misija yra įveikti bendravimo kliūtis bei suburti žmones pasitelkus kalbų mokymąsi.`,
    },
    why: {
      title: "Kodėl mes?",
      config: [
        {
          title: "Pažangūs mokymo metodai",
          image: dlaczego1,
          description:
            "Mūsų modernus ir praktika patikrintas mokymosi algoritmas mokymo medžiagą pritaiko prie jūsų esamo žinių lygio ir jį didina teikiant efektyvų mokymą.",
        },
        {
          title: "Didelis kalbų pasirinkimas",
          image: dlaczego2,
          description:
            "Siūlome pasaulyje daugiausiai vartojamų kalbų kursus, tad galite pasirinkti kalbą, atitinkančią jūsų poreikius.",
        },
        {
          title: "Mokymasis smagiai",
          image: dlaczego3,
          description:
            "Mūsų platforma kalbos mokymąsi paverčia smagia ir malonia patirtimi, tad būsite motyvuoti jį tęsti.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Pritaikymas prie individualių poreikių ir prieiga",
          description:
            "Mūsų galingas mašininio mokymosi algoritmas nuolat pluša, kad pateiktų kiekvienam asmeniui tinkamo lygio mokymosi medžiagą. „eLingo“ besimokantiesiems leidžia patiems rasti kalbos modelius, dėmesio nesutelkiant į  taisykles – tuo pačiu būdu, kaip vaikystėje išmokote savo gimtosios kalbos.",
          image: zdjecie1,
        },
        {
          title: "Drąsus technologijų ir mokslo derinys",
          description:
            "„eLingo“ drąsiai derinamos technologijos ir mokslas, taip siekiant sužadinti jūsų protą veiklai. Kartoti nesuskaičiuojamą daugybę žodžių ir frazių sąrašų yra nuobodu ir jūsų smegenys tai žino. Mūsų metodas, kuriuo esate verčiami įvairiais būdais įgytas žinias panaudoti konkrečiu metu, yra įdomus ir veiksmingas – jūsų smegenys tai įvertins!",
          image: zdjecie2,
        },
        {
          title: "Bendruomenė ir vertybės",
          description:
            "Kalbos padeda žmonėms užmegzti ryšius. Kalbų mokymasis skatina supratimą. „eLingo“ bendruomenė suburia žmones iš viso pasaulio. Esame ambicingi ir visad plečiame ribas. Manome, kad mokytis kalbų turėtų būti smagu, ir mus domina pažinti naujas kultūras pasitelkus kalbas.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Susidomėjote kursu?",
      title1: "Norėtumėte daugiau informacijos?",
      subtitle: "Palikite savo telefono numerį ir mes jums paskambinsime.",
      subtitle1:
        "If you already have an account, please fill in the following fields:",
      button1: "Vardas ir pavardė",
      button2: "Telefono numeris",
      button3: "El. paštas",
      buttonSub: "Pateikti užklausą skambučiui",
      checkOne:
        "Pateikdamas šią formą sutinku su sąlygomis ir pareiškiu, kad susipažinau su sąlygomis ir duomenų apsaugos taisyklėmis.",
      checkOneTwo: "",
      checkTwo: "Sutinku, kad su manimi susisiektų rinkodaros reikmėms",
      textOne: "Pranešame, kad jūsų asmens duomenų valdytojas yra",
      textTwo:
        "EOU Sp. z o.o., kurio būstinė yra Varšuvoje, ul. Młynarska 42 / 115, 01-171, įregistruotas Varšuvos apygardos teismo tvarkomame verslininkų registre numeriais KRS 0001038089, REGON 525396900, NIP 5273057993. Jūsų asmens duomenys bus tvarkomi dėl kontaktinės formos užklausos arba sudarytos sutarties vykdymo. Susisiekdami su mumis savanoriškai sutinkate, kad jūsų asmens duomenys būtų tvarkomi minėtais tikslais. Turite teisę su savo duomenimis susipažinti, juos ištaisyti, ištrinti ar apriboti jų tvarkymą, nesutikti, kad jie būtų tvarkomi, ir teisę į duomenų perkeliamumą. Taip pat turite teisę bet kada atšaukti savo sutikimą, tačiau sutikimo atšaukimas neturi įtakos duomenų tvarkymo, grindžiamo sutikimu iki atšaukiant, teisėtumui. Be to, turite teisę pateikti skundą priežiūros institucijai. Duomenų pateikimas yra savanoriškas, tačiau gali būti privalomas norint atsakyti į užklausą arba įvykdyti sutartį. Asmens duomenys bus saugomi tol, kol tai bus būtina siekiant pirmiau nurodytų tikslų, bet ne ilgiau nei pagal bendrąją teisę nustatytą reikalavimų senaties terminą. Prašome atidžiai perskaityti privatumo politiką.",
      buttonMore: "Skaityti daugiau",
      buttonLess: "Skaityti mažiau",
      subOne: "Paslaugų teikimo sąlygos",
      subTwo: "ir privatumo taisykles.",
      buttonTXT: "Thank you",
      rulesLink: "/Regulations_LT.pdf",
      privacyLink: "/Privacy_LT.pdf",
      product: "el",
      market: "lt",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "lt-en",
      server_link: "https://cms.eou-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    privacy: "Privatumas",
    privacyLink: "/Privacy_LT.pdf",
    rules: "Paslaugų teikimo sąlygos",
    rulesLink: "/Regulations_LT.pdf",
  },
};
