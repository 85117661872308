import React, { useContext, useEffect, useState } from "react";
import englishFlag from "../../product/images/languages/angielski.png";
import polishFlag from "../../product/images/languages/polski_o.png";
import hungaryFlag from "../../product/images/languages/dymek_wegry.png";
import czechFlag from "../../product/images/languages/czeski_o.png";
import slovakFlag from "../../product/images/languages/slowacki_o.png";
import romanianlag from "../../product/images/languages/rumunski_o.png";
import croatianFlag from "../../product/images/languages/dymek_chorwacki.png";
import lithuanianFlag from "../../product/images/languages/dymek_litewski.png";
import latvianFlag from "../../product/images/languages/dymek_lotwa.png";

import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Store from "../../Store";
import Dropdown from "react-dropdown";
import { LanguageContext } from "../contexts/LanguageContext";
import "./navbarStyles.css";

export const LanguageChanger = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { lang, setLang } = useContext(LanguageContext);

  const options = [
    {
      value: "en",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={englishFlag} alt="English"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            English
          </p>{" "}
        </div>
      ),
    },
    // {  value: "pl", label: <div className="Dropdown-items navAnimation"> <img width={35} src={polishFlag} alt="Polski" ></img><p style={{fontSize: "16px"}} className="dontshow"> Polski</p></div> },
    // {  value: "cs",label: <div className="Dropdown-items navAnimation"><img width={35} src={czechFlag} alt="Čeština"></img><p style={{fontSize: "16px"}} className="dontshow"> Čeština</p></div> },
    {
      value: "sk",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={slovakFlag} alt="Slovenčina"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Slovenský
          </p>
        </div>
      ),
    },
    {
      value: "ro",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={romanianlag} alt="Română"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Română
          </p>
        </div>
      ),
    },
    {
      value: "hu",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={hungaryFlag} alt="Română"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Magyar
          </p>
        </div>
      ),
    },
    {
      value: "lt",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={lithuanianFlag} alt="Lietuvių"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Lietuvių
          </p>
        </div>
      ),
    },
    {
      value: "cs",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={czechFlag} alt="Česky"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Česky
          </p>
        </div>
      ),
    },
    {
      value: "lv",
      label: (
        <div className="Dropdown-items navAnimation">
          <img width={35} src={latvianFlag} alt="Latviešu"></img>
          <p style={{ fontSize: "16px" }} className="dontshow">
            {" "}
            Latviešu
          </p>
        </div>
      ),
    },
  ];

  const handleSelect = ({ value }) => {
    const newLang = value;
    setLang(newLang);
    Store.setUserLanguage(newLang);
    Store.setUserPlatformLanguage(newLang);
    const pathParts = pathname.split("/");
    pathParts[1] = newLang;
    navigate(pathParts.join("/"));
  };

  return (
    <div>
      <Dropdown
        options={options}
        onChange={handleSelect}
        value={lang}
        placeholder="Select an option"
        controlClassName={"mySuperClass"}
      />
    </div>
  );
};
