import styled from "styled-components";

const PhotoWraper = styled.div`
  width: 110px;
  overflow: hidden;

  @media (max-width: 600px) {
    width: 90px;
  }
`;

const LanguageName = styled.h4`
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #1e2d6a;
`;

const LanguageItem = ({ image, lang }) => {
  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <PhotoWraper>
        <img src={image} alt="logo" style={{ width: "100%", height: "auto" }} />
      </PhotoWraper>
      <LanguageName>{lang}</LanguageName>
    </div>
  );
};

export default LanguageItem;
