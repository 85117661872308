import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import LanguageItem from "./LanguageItem";

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 700px;
  gap: 20px;
  margin-top: 30px;

  @media (max-width: 1200px) {
    max-width: 700px;
  }

  @media (max-width: 600px) {
    max-width: 350px;
  }
`;

const SectionThree = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: ${Colors.mainColor};
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 900;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`;

const Text = styled.div`
  text-align: center;
  color: ${Colors.black};
  font-size: 16px;
  max-width: 1100px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Languages = ({ data }) => {
  return (
    <>
      <SectionThree>
        <Title>{data.title}</Title>
        <Text>{data.description}</Text>
        <LanguageWrapper>
          {data.languages.map(({ image, lang }) => (
            <LanguageItem image={image} lang={lang} />
          ))}
        </LanguageWrapper>
      </SectionThree>
    </>
  );
};

export default Languages;
