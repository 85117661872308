import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";

import screen1 from "../images/newProductPage/czech/1.png";
import screen2 from "../images/newProductPage/czech/2.png";
import screen3 from "../images/newProductPage/czech/3.png";
import screen4 from "../images/newProductPage/czech/4.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const english = {
  navbar: {
    data: [
      { title: "Order a Course", path: "/course" },
      { title: "Login", path: "/start" },
      { title: "eLingo", path: "/" },
      { title: "About Us", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      language: "en",
      hero: {
        languages: [
          "in English",
          "in Spanish",
          "in German",
          "in Dutch",
          "in Italian",
          "in Russian",
          "in Polish",
          "in French",
          "in Portuguese",
        ],
        title: "Speak bolder,",
        prefix: "with confidence",
        subtitle:
          "Gain language skills with our course based on real-life examples!",
        buttonText: "Order now!",
      },
      learning: {
        title: "What does the course look like?",
        config: [
          {
            image: screen1,

            category: "Learn the language in the easiest way",
            descriptions: [
              {
                bold: "Dozens of lessons",
                rest: "with examples based on everyday communication.",
              },
              {
                bold: "Practical phrases",
                rest: "designed by native speakers.",
              },
              {
                bold: "Exercises",
                rest: "that effectively develop reading, listening, and speaking.",
              },
            ],
          },
          {
            image: screen2,
            category: "Practical lessons",
            description:
              "Each learning level consists of dozens of lessons tailored to your needs.",
          },
          {
            image: screen3,
            category: "Assignments",
            description:
              "Our exercises effectively support material repetition and improve memory.",
          },
          {
            image: screen4,
            category: "Words, phrases, and expressions",
            description:
              "Each lesson includes carefully selected images that make the learning process intuitive.",
          },
        ],
        photoData: [screen1],
      },
      languages: {
        languages: [
          { image: new_england, lang: "English" },
          { image: new_french, lang: "French" },
          { image: new_spanish, lang: "Spanish" },
          { image: new_niderland, lang: "Dutch" },
          { image: new_germany, lang: "German" },
          { image: new_italy, lang: "Italian" },
          { image: new_russia, lang: "Russian" },
          { image: new_portugal, lang: "Portuguese" },
          { image: new_polish, lang: "Polish" },
        ],
        title: "Available languages:",
        description:
          "Prepare for your next trip or add language skills to your resume.",
      },
      expertSection: {
        title: "Your language expert",
        firstSectionTitle: "Support at your fingertips",
        firstSectionDescription: "Get full support while studying!",
        secondSectionTitle: "Ask questions and get answers",
        secondSectionDescription:
          "You can ask any question directly to your expert.",
        thirdSectionTitle: "Individual approach every step of the way",
        thirdSectionDescription:
          "Using the expert's advice speeds up your learning process.",
      },
      courseValue: {
        title: "Get more with eLingo",
        subtitle: "Not just language learning – gain full access to tools.",
        buttonText: "Order now!",
        config: [
          {
            title: "Learning tailored to your lifestyle",
            sections: [
              {
                text: "Learn wherever and whenever you want, thanks to our platform.",
              },
              {
                text: "Short lessons perfectly fit into your daily schedule.",
              },
            ],
          },
          {
            title: "Motivation at every step",
            sections: [
              {
                text: "Keep your streak going with our tracking system.",
              },
              {
                text: "Every day of learning brings you closer to fluency.",
              },
            ],
          },
          {
            title: "Full flexibility and personalization",
            sections: [
              {
                text: "Tailor the course to your level and learning pace.",
              },
              {
                text: "Focus on what interests you the most.",
              },
            ],
          },
        ],
      },
    },
  },
  aboutUs: {
    hero: {
      title: "Mission",
      description: `We believe that language forms a bridge between cultures, and our mission is to overcome communication barriers and bring people together through language education.`,
    },
    why: {
      title: "Why Us?",
      config: [
        {
          title: "Innovative Educational Methods",
          image: dlaczego1,
          description:
            "Our sophisticated and practice-tested learning algorithm adjusts the materials to your current level of knowledge and expands it through effective teaching.",
        },
        {
          title: "Wide Range of Languages",
          image: dlaczego2,
          description:
            "We offer courses in the world's most popular languages. This allows you to choose the language that suits your needs.",
        },
        {
          title: "Education in a Fun Way",
          image: dlaczego3,
          description:
            "Our platform makes language learning a fun and enjoyable experience, so you will be motivated to learn languages.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Personalization and Accessibility",
          description:
            "Our efficient machine learning algorithm constantly works to provide appropriate level materials for every individual. eLingo enables learners to discover patterns on their own without having to focus on the rules of language – just like you learned your mother tongue in childhood.",
          image: zdjecie1,
        },
        {
          title: "Combination of Technology and Science",
          description:
            "eLingo uses a bold combination of technology and science to stimulate the mind into action. Repeating countless lists of words and expressions is boring, and your brain knows it. Our method is exciting and effective, forcing you to use acquired knowledge in different ways at specific moments - your brain will appreciate this solution!",
          image: zdjecie2,
        },
        {
          title: "Community and Values",
          description:
            "Languages help people commit. Learning languages facilitates understanding. The eLingo community brings people together from all over the world. We are ambitious and always pushing boundaries. We believe that learning languages can be fun and are curious to explore new cultures through language.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Interested in the course? ",
      title1: "Would you like more information?",
      subtitle: "Leave your phone number - we will call you back.",
      subtitle1:
        "If you already have an account, please fill in the following fields:",
      button1: "Name and Surname",
      button2: "Phone Number",
      button3: "Email",
      buttonSub: "Send",
      checkOne:
        "By submitting this form, I accept the terms and declare that I have read the following",
      checkOneTwo: "the",
      checkTwo: "I consent to being contacted for marketing purposes",
      textOne: "Please be informed that",
      textTwo:
        "The controller of your personal data is EOU Sp. z o.o., headquartered in Warsaw, Młynarska 42 / 115, 01-171, registered in the entrepreneurs' register maintained by the district court of Warsaw under KRS5273057993, REGON: 525396900, NIP5273057993. Your personal data will be processed in relation to the contact form inquiry or the execution of the concluded contract. By contacting us, you voluntarily consent to the processing of your personal data for the aforementioned purposes. You have the right to access, correct, delete, or restrict processing of your data, to object to processing, and to data portability. You also have the right to withdraw your consent at any time, but the withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal. You also have the right to file a complaint with the supervisory authority. Providing data is voluntary but may be necessary to respond to an inquiry or execute a contract. Personal data will be stored for the period necessary for the above purposes, but not longer than the period of limitation of claims under general law. Please read the privacy policy carefully",
      buttonMore: "Read more",
      buttonLess: "Read less",
      subOne: "terms",
      subTwo: "and privacy rules.",
      buttonTXT: "Thank you",
      rulesLink: "/Regulations_EN.pdf",
      privacyLink: "/Privacy_EN.pdf",
      product: "es",
      market: "sk",
      send_to_url: "https://crm.eou-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "sk-en",
      server_link: "https://cms.eou-assets.eu/integration/lead-from-landing",
      currency: "eur",
      price: 87,
    },
  },
  footer: {
    privacy: "Privacy",
    privacyLink: "/Privacy_EN.pdf",
    rules: "Terms of service",
    rulesLink: "/Regulations_EN.pdf",
  },
};
