import React from "react";
import styled from "styled-components";
import { Colors } from "../../../colors/Colors";
import top from "./images/top.svg";
import bottom from "./images/down.svg";

import phone from "../../../images/newProductPage/phone/phone_mockup.png";
import Slider from "react-slick";

const Wrapper = styled.section`
  background-color: ${Colors.gray};
  padding: 60px 0 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    margin-top: -100px;
  }
`;

const SecondSection = styled.div`
  display: flex;
  gap: 220px;
  align-items: center;
  max-width: 1200px;

  @media (max-width: 1200px) {
    gap: 220px;
  }

  @media (max-width: 1000px) {
    gap: 0px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const Title = styled.h1`
  color: ${Colors.lightGreen};
  font-size: 40px;
  margin-bottom: 20px;
  font-weight: 900 !important;

  @media (max-width: 600px) {
    font-size: 36px;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding: 0px;
  width: 120px;
  border-radius: 30px !important;
  transform: translateZ(0);
  overflow: hidden;
`;

const SecondSectionTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 30px;
  margin-top: 40px;
  max-width: 1200px;

  @media (max-width: 1200px) {
    margin-top: 0px;
  }

  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    margin-top: 80px;
    flex-direction: column;
  }
`;

const SecondTextWrapper = styled.div`
  color: white;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .element__wrapper:first-of-type {
    .description {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:last-of-type {
      display: none;
    }
  }

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
};

const SliderMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    height: 500px;
    overflow: hidden;
    margin-bottom: 50px;
  }
`;

const SliderDestop = styled.div`
  width: 295px;
  height: 600px;
  display: flex;
  align-items: center;
  margin-top: -50px;
  overflow: hidden;

  @media (max-width: 800px) {
    display: none;
  }
`;

const ImageWrapperCase = styled.div`
  position: absolute;
  width: 120px;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  max-width: 800px;

  :hover {
    p {
      display: flex;
    }

    img:first-of-type {
      display: flex;
    }

    img:last-of-type {
      display: none;
    }
  }
`;

const Category = styled.h1`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  color: ${Colors.mainColor};
  text-align: left;
  font-size: 24px;
  font-weight: 800 !important;

  img:first-of-type {
    display: none;
  }

  img:last-of-type {
    display: flex;
  }
`;

const Description = styled.p`
  color: ${Colors.mainColor};
  font-size: 16px !important;
  display: none;
  max-width: 500px;
  animation: 1s slide-top;

  @keyframes slide-top {
    from {
      margin-top: -100px;
    }

    to {
      margin-left: 0%;
    }
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.position % 2 === 0 ? "row" : "row-reverse"};

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const DescriptionWrapper = styled.p`
  display: flex;
  gap: 8px;
  margin-top: 2px;
  list-style-type: circle;

  color: #1e2d6a;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Point = styled.div`
  margin-top: 8px;
  min-height: 7px;
  max-height: 7px;
  min-width: 7px;
  max-width: 7px;
  background-color: #1e2d6a;
  border-radius: 50%;
  margin-left: 10px;
`;

const ItemWrapper = styled.div`
  width: 500px;

  @media (max-width: 1000px) {
    width: 400px;
  }

  @media (max-width: 800px) {
    width: 300px;
  }

  @media (max-width: 600px) {
    width: 90%;
    margin-top: 40px;
  }
`;

const ItemWrapperImage = styled(ItemWrapper)`
  display: flex;
  justify-content: center;
`;

const Learning = ({ data }) => {
  return (
    <Wrapper>
      <SecondSectionTitle>
        <Title>{data.title}</Title>
      </SecondSectionTitle>
      {data.config.map((c, i) => (
        <ItemsWrapper position={i}>
          <ItemWrapper>
            <Category>{c.category}</Category>
            <div style={{ marginTop: "12px" }}>
              <DescriptionWrapper>{c.description}</DescriptionWrapper>
              <ul>
                {c.descriptions?.map((d) => (
                  <DescriptionWrapper>
                    <Point />
                    <div>
                      <b>{d.bold}</b> {d.rest}
                    </div>
                  </DescriptionWrapper>
                ))}
              </ul>
            </div>
          </ItemWrapper>
          <ItemWrapperImage>
            <ImageWrapper>
              <ImageWrapperCase>
                <img
                  src={phone}
                  alt="phone"
                  style={{ width: "100%", height: "242px" }}
                />
              </ImageWrapperCase>
              <div
                style={{
                  marginLeft: "8px",
                  width: "105px",
                  height: "242px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={c.image}
                  alt="Mobile Verbalia"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </ImageWrapper>
          </ItemWrapperImage>
        </ItemsWrapper>
      ))}
    </Wrapper>
  );
};

export default Learning;
