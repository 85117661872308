import continueLearningCs from "./cs/continue_learning.png";
import allLessonsCs from "./cs/all_lessons.png";
import lessonsListCs from "./cs/lessons_list.png";
import learningLevelCs from "./cs/learning_level.png";
import yourActivityCs from "./cs/your_activity.png";
import activeCourseCs from "./cs/active_course.png";
import otherCoursesCs from "./cs/other_courses.png";
import sidebarMenuCs from "./cs/sidebar_menu.png";
import lessonTutorialCs from "./cs/lesson_tutorial.png";
import testCs from "./cs/test.png";

import continueLearningSk from "./sk/continue_learning.png";
import allLessonsSk from "./sk/all_lessons.png";
import lessonsListSk from "./sk/lessons_list.png";
import learningLevelSk from "./sk/learning_level.png";
import yourActivitySk from "./sk/your_activity.png";
import activeCourseSk from "./sk/active_course.png";
import otherCoursesSk from "./sk/other_courses.png";
import sidebarMenuSk from "./sk/sidebar_menu.png";
import lessonTutorialSk from "./sk/lesson_tutorial.png";
import testSk from "./sk/test.png";

import continueLearningRo from "./ro/continue_learning.png";
import allLessonsRo from "./ro/all_lessons.png";
import lessonsListRo from "./ro/lessons_list.png";
import learningLevelRo from "./ro/learning_level.png";
import yourActivityRo from "./ro/your_activity.png";
import activeCourseRo from "./ro/active_course.png";
import otherCoursesRo from "./ro/other_courses.png";
import sidebarMenuRo from "./ro/sidebar_menu.png";
import lessonTutorialRo from "./ro/lesson_tutorial.png";
import testRo from "./ro/test.png";

import continueLearningHu from "./hu/continue_learning.png";
import allLessonsHu from "./hu/all_lessons.png";
import lessonsListHu from "./hu/lessons_list.png";
import learningLevelHu from "./hu/learning_level.png";
import yourActivityHu from "./hu/your_activity.png";
import activeCourseHu from "./hu/active_course.png";
import otherCoursesHu from "./hu/other_courses.png";
import sidebarMenuHu from "./hu/sidebar_menu.png";
import lessonTutorialHu from "./hu/lesson_tutorial.png";
import testHu from "./hu/test.png";

import continueLearningBg from "./bg/continue_learning.png";
import allLessonsBg from "./bg/all_lessons.png";
import lessonsListBg from "./bg/lessons_list.png";
import learningLevelBg from "./bg/learning_level.png";
import yourActivityBg from "./bg/your_activity.png";
import activeCourseBg from "./bg/active_course.png";
import otherCoursesBg from "./bg/other_courses.png";
import sidebarMenuBg from "./bg/sidebar_menu.png";
import lessonTutorialBg from "./bg/lesson_tutorial.png";
import testBg from "./bg/test.png";

import continueLearningLt from "./lt/continue_learning.png";
import allLessonsLt from "./lt/all_lessons.png";
import lessonsListLt from "./lt/lessons_list.png";
import learningLevelLt from "./lt/learning_level.png";
import yourActivityLt from "./lt/your_activity.png";
import activeCourseLt from "./lt/active_course.png";
import otherCoursesLt from "./lt/other_courses.png";
import sidebarMenuLt from "./lt/sidebar_menu.png";
import lessonTutorialLt from "./lt/lesson_tutorial.png";
import testLt from "./lt/test.png";

import continueLearningLv from "./lv/continue_learning.png";
import allLessonsLv from "./lv/all_lessons.png";
import lessonsListLv from "./lv/lessons_list.png";
import learningLevelLv from "./lv/learning_level.png";
import yourActivityLv from "./lv/your_activity.png";
import activeCourseLv from "./lv/active_course.png";
import otherCoursesLv from "./lv/other_courses.png";
import sidebarMenuLv from "./lv/sidebar_menu.png";
import lessonTutorialLv from "./lv/lesson_tutorial.png";
import testLv from "./lv/test.png";

import Store from "../../../../Store";

const langImg = {
  continueLearning: {
    cs: continueLearningCs,
    sk: continueLearningSk,
    ro: continueLearningRo,
    hu: continueLearningHu,
    lv: continueLearningLv,
    lt: continueLearningLt,
    bg: continueLearningBg,
  },
  allLessons: {
    cs: allLessonsCs,
    sk: allLessonsSk,
    ro: allLessonsRo,
    hu: allLessonsHu,
    lv: allLessonsLv,
    lt: allLessonsLt,
    bg: allLessonsBg,
  },
  lessonsList: {
    cs: lessonsListCs,
    sk: lessonsListSk,
    ro: lessonsListRo,
    hu: lessonsListHu,
    lv: lessonsListLv,
    lt: lessonsListLt,
    bg: lessonsListBg,
  },
  learningLevel: {
    cs: learningLevelCs,
    sk: learningLevelSk,
    ro: learningLevelRo,
    hu: learningLevelHu,
    lv: learningLevelLv,
    lt: learningLevelLt,
    bg: learningLevelBg,
  },
  yourActivity: {
    cs: yourActivityCs,
    sk: yourActivitySk,
    ro: yourActivityRo,
    hu: yourActivityHu,
    lv: yourActivityLv,
    lt: yourActivityLt,
    bg: yourActivityBg,
  },
  activeCourse: {
    cs: activeCourseCs,
    sk: activeCourseSk,
    ro: activeCourseRo,
    hu: activeCourseHu,
    lv: activeCourseLv,
    lt: activeCourseLt,
    bg: activeCourseBg,
  },
  otherCourses: {
    cs: otherCoursesCs,
    sk: otherCoursesSk,
    ro: otherCoursesRo,
    hu: otherCoursesHu,
    lv: otherCoursesLv,
    lt: otherCoursesLt,
    bg: otherCoursesBg,
  },
  sidebarMenu: {
    cs: sidebarMenuCs,
    sk: sidebarMenuSk,
    ro: sidebarMenuRo,
    hu: sidebarMenuHu,
    lv: sidebarMenuLv,
    lt: sidebarMenuLt,
    bg: sidebarMenuBg,
  },
  lessonTutorial: {
    cs: lessonTutorialCs,
    sk: lessonTutorialSk,
    ro: lessonTutorialRo,
    hu: lessonTutorialHu,
    lv: lessonTutorialLv,
    lt: lessonTutorialLt,
    bg: lessonTutorialBg,
  },
  test: {
    cs: testCs,
    sk: testSk,
    ro: testRo,
    hu: testHu,
    lv: testLv,
    lt: testLt,
    bg: testBg,
  },
};

const getTutorialImage = (imgName, langShort) => {
  return langImg[imgName][langShort];
};

export const isTutorialAvailable = () => {
  const lang = Store.getUserPlatformLangShort();
  return Object.values(langImg).every((imageObj) => !!imageObj[lang]);
};

export default getTutorialImage;
