import React from "react";
import IkonkaOne from "./images/ikona1.png";
import IkonkaTwo from "./images/ikona2.png";
import IkonkaThree from "./images/ikona3.png";
import styled from "styled-components";

const SectionLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #f5f5f5;
  padding: 30px 22vw;

  @media (max-width: 1500px) {
    padding: 0 12vw;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    gap: 12px;
    padding: 20px;
  }
`;

const SectionRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto;
  gap: 150px;
  padding: 0 22vw;

  @media (max-width: 1400px) {
    padding: 0 12vw;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 12px;
  }
`;

const Description = styled.div`
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 22px;

  @media (max-width: 600px) {
    text-align: center;
    max-width: 260px;
    margin: 12px auto 0 auto;
    font-size: 14px;
  }
`;

const Title = styled.div`
  color: #1e2d6a;
  text-align: left;

  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  @media (max-width: 600px) {
    text-align: center;
    max-width: 260px;
    margin: 0 auto;
    font-size: 20px;
  }
`;

const TextWrapper = styled.div`
  width: fit-content;
  max-width: 500px;
  text-align: left;
`;

const SectionTitle = styled.div`
  color: #1e2d6a;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  padding: 30px 0 0px 0;
  background: #f5f5f5;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`;

const ImageWrapper = styled.div`
  @media (max-width: 600px) {
    width: 100px;
    height: 100px;

    img {
      width: auto;
      height: 100%;
    }
  }
`;

const ExpertSection = ({ data }) => {
  return (
    <div style={{ marginTop: "60px" }}>
      <SectionTitle>{data.title}</SectionTitle>
      <SectionLeft>
        <TextWrapper>
          <Title>{data.firstSectionTitle}</Title>
          <Description>{data.firstSectionDescription}</Description>
        </TextWrapper>
        <div>
          <ImageWrapper>
            <img src={IkonkaOne} />
          </ImageWrapper>
        </div>
      </SectionLeft>
      <SectionRight>
        <div>
          <ImageWrapper>
            <img src={IkonkaTwo} />
          </ImageWrapper>
        </div>
        <TextWrapper>
          <Title>{data.secondSectionTitle}</Title>
          <Description>{data.secondSectionDescription}</Description>
        </TextWrapper>
      </SectionRight>
      <SectionLeft>
        <TextWrapper>
          <Title>{data.thirdSectionTitle}</Title>
          <Description>{data.thirdSectionDescription}</Description>
        </TextWrapper>
        <div>
          <ImageWrapper>
            <img src={IkonkaThree} />
          </ImageWrapper>
        </div>
      </SectionLeft>
    </div>
  );
};

export default ExpertSection;
