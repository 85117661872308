import Table from "rc-table";
import { useEffect, useState } from "react";
import api from "../../../platform/api/api";
import "./UserTable.css";

import MainModal from "../../../platform/components/Modals/MainModal/MainModal";
import { SendCertificateModal } from "./components/SendCertificateModal";
import { UserLanguagesModal } from "./components/UserLanguagesModal";
const queryString = require("query-string");

const PER_PAGE = 100;

const Pagination = ({ offset, limit, allCount, changePage }) => {
  return (
    <div className="user-table-pagination-wrapper">
      {" "}
      <button
        disabled={offset <= 0}
        onClick={() => changePage(-1)}
        className="user-table-pagination-button"
      >
        Poprzednia strona
      </button>
      <div>
        <p>
          Strona: {Math.floor(offset / limit) + 1} z{" "}
          {Math.ceil(allCount / limit)}
        </p>
        <p>
          Wyświetlani użytkownicy: {offset + 1} -{" "}
          {offset + limit < allCount ? offset + limit : allCount} z {allCount}
        </p>
      </div>
      <button
        disabled={offset + limit >= allCount}
        onClick={() => changePage(1)}
        className="user-table-pagination-button"
      >
        Następna Strona
      </button>
    </div>
  );
};

function UserTable(props) {
  const onCheckProgress = async (id) => {
    const res = await api.get(`/user/progress/${id}`);
    const stringPorgress = Object.keys(res.data)
      .map((key) => `${key}: ${Math.floor(res.data[key] * 100)}%`)
      .join("\n");
    alert(stringPorgress);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Is blocked",
      dataIndex: "isBlocked",
      key: "isBlocked",
      width: 50,

      render: (row) => {
        return row ? "x" : "";
      },
    },
    {
      title: "Last lesson",
      dataIndex: "lastLesson",
      key: "lastLesson",
      width: 200,

      render: (row) => {
        return (
          row &&
          `(${row.languagePair.languageFrom.language}-${row.languagePair.languageTo.language} poziom: ${row.level}, numer lekcji: ${row.number}.${row.subNumber})`
        );
      },
    },

    {
      title: "Akcje",
      dataIndex: "id",
      key: "id",

      render: (row) => {
        return (
          <div className="user-table__buttons-wrapper">
            <button
              className="user-table__edit-button"
              onClick={() => {
                onCheckProgress(row);
              }}
            >
              Sprawdź progress
            </button>
            <button
              className="user-table__edit-button"
              onClick={() => {
                setChangePasswordModalOpen(true);
                setCurrentUser(row);
              }}
            >
              Zmień hasło
            </button>
            <button
              className="user-table__edit-button user-table__edit-button--red"
              onClick={() => onToggleBlock(row)}
            >
              {" "}
              Zablokuj / Odblokuj
            </button>
            <button
              className="user-table__edit-button user-table__edit-button--green"
              onClick={() => {
                setSendCertificateModalOpen(true);
                setCurrentUser(row);
              }}
            >
              Certyfikat
            </button>
            <button
              className="user-table__edit-button"
              onClick={() => {
                setUserLanguageModalOpen(true);
                setCurrentUser(row);
              }}
            >
              Pary językowe
            </button>
          </div>
        );
      },
    },
  ];

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentEmail, setCurrentEmail] = useState("");

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const [sendCertificateModalOpen, setSendCertificateModalOpen] =
    useState(false);

  const [userLanguageModalOpen, setUserLanguageModalOpen] = useState(false);

  const [password, setPassword] = useState("");
  const [currentUser, setCurrentUser] = useState();

  const afterUpdate = async () => {
    await load(currentPage, currentEmail);
    setCurrentUser();
    setChangePasswordModalOpen(false);
    setUserLanguageModalOpen(false);
  };

  const changePage = async (diff) => {
    setCurrentPage(currentPage + diff);
    await load(currentPage + diff, currentEmail);
  };

  const load = async (page, email) => {
    const qsObj = {
      offset: (page - 1) * PER_PAGE,
      limit: PER_PAGE,
      email,
    };
    const res = await api.get(`/user?${queryString.stringify(qsObj)}`);
    setCount(res.data.count);
    setUsers(res.data.users.filter((user) => user.id !== 1));
  };

  const findUsers = async () => {
    setCurrentPage(1);
    await load(1, currentEmail);
  };

  const onToggleBlock = async (id) => {
    await api.post(`/user/${id}/toggle-block`);
    await afterUpdate();
  };

  const onPasswordChange = async (e) => {
    e.preventDefault();

    await api.post(`/user/${currentUser}/change-password`, { password });
    await afterUpdate();
  };

  useEffect(() => {
    setPassword("");
  }, [changePasswordModalOpen]);

  useEffect(() => {
    load(currentPage, currentEmail);
  }, []);

  return (
    <div
      style={{
        backgroundColor: `#fff`,
        minHeight: "100vh",
        backgroundSize: "cover",
      }}
    >
      <div className="user-table">
        <div>
          <input
            value={currentEmail}
            onChange={(e) => setCurrentEmail(e.target.value)}
            placeholder="email bądź jego część"
          />
          <button onClick={findUsers} className="user-table-button-std">
            Znajdź
          </button>
        </div>
        <Pagination
          offset={(currentPage - 1) * PER_PAGE}
          limit={PER_PAGE}
          allCount={count}
          changePage={changePage}
        />

        <Table
          data={users}
          columns={columns}
          rowClassName="user-table__row"
          onRow={(row) => {
            return {
              onClick: () => {
                // setCurrentFlashcard(row);
                // setIsModalOpen(true);
              },
            };
          }}
        ></Table>
        <MainModal
          setIsModalOpen={setChangePasswordModalOpen}
          isModalOpen={changePasswordModalOpen}
        >
          <form onSubmit={onPasswordChange}>
            {currentUser && (
              <p>{users.find((user) => user.id === currentUser).email}</p>
            )}
            <input
              placeholder="hasło"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <button type="submit" className="user-table-button-std">
              Zapisz
            </button>
          </form>
        </MainModal>
        <SendCertificateModal
          isModalOpen={sendCertificateModalOpen}
          setIsModalOpen={setSendCertificateModalOpen}
          afterUpdate={afterUpdate}
          users={users}
          userId={currentUser}
        ></SendCertificateModal>
        <Pagination
          offset={(currentPage - 1) * PER_PAGE}
          limit={PER_PAGE}
          allCount={count}
          changePage={changePage}
        />
        <UserLanguagesModal
          isModalOpen={userLanguageModalOpen}
          setIsModalOpen={setUserLanguageModalOpen}
          users={users}
          userId={currentUser}
          afterUpdate={afterUpdate}
        />
      </div>
    </div>
  );
}

export default UserTable;
