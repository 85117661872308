import { useEffect, useState } from "react";
import api from "../../../../platform/api/api";
import { getIsCertificateEnabled } from "../../../../platform/components/Modals/CertificateModal/helpers/getIsCertificateEnabled/getIsCertificateEnabled";
import MainModal from "../../../../platform/components/Modals/MainModal/MainModal";

export const UserLanguagesModal = ({
  userId,
  users,
  setIsModalOpen,
  isModalOpen,
  afterUpdate,
}) => {
  const [languagePairs, setLanguagePairs] = useState([]);
  const [userLanguagePairs, setUserLanguagePairs] = useState([]);

  useEffect(() => {
    Promise.all([api.get(`/language-pair?version=1`)]).then(([resV1]) => {
      const sorted = [...resV1.data.sort((a, b) => a.id - b.id)]
        .sort((a, b) =>
          a.languageFrom.language.localeCompare(b.languageFrom.language)
        );
      setLanguagePairs(sorted);
    });
  }, []);

  useEffect(() => {
    if (!userId) return;

    const user = users.find(({ id }) => id === userId);
    setUserLanguagePairs(user.languagePairs.map((pair) => pair.id));
  }, [userId]);

  const onSave = async (e) => {
    e.preventDefault();

    await api.post(`/user/language-pairs`, {
      userId,
      languagePairIds: userLanguagePairs,
    });

    afterUpdate();
  };

  const onPairChange = (checked, pairId) => {
    if (checked) setUserLanguagePairs((prev) => [...prev, pairId]);
    else
      setUserLanguagePairs((prev) => {
        const newPairs = prev.filter((id) => id !== pairId);
        return newPairs;
      });
  };

  return (
    <MainModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}>
      <div
        style={{
          display: "grid",
          flexDirection: "row",
          gap: "10px",
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        {languagePairs.map((pair) => (
          <div
            style={{ display: "flex", alignItems: "center", gridGap: "5px" }}
          >
            <input
              type="checkbox"
              checked={userLanguagePairs.find((pairId) => pairId === pair.id)}
              onChange={(e) => onPairChange(e.target.checked, pair.id)}
            />
            {pair.languageFrom.language}-{pair.languageTo.language}
          </div>
        ))}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type="button"
            className="user-table-button-std"
            onClick={onSave}
          >
            Zapisz
          </button>
        </div>
      </div>
    </MainModal>
  );
};
