import React from "react";
import Hero from "../../components/newNewProductPage/Hero/Hero";
import Languages from "../../components/newNewProductPage/Languages/Languages";
import Learning from "../../components/newNewProductPage/Learning/Learning";
import ExpertSection from "../../components/newNewProductPage/expert/ExpertSection";
import CourseValue from "../../components/newNewProductPage/courseValue/CourseValue";

const NewNewProductPage = ({ data = {} }) => {
  console.log(data.expertSection);
  return (
    <div>
      <Hero data={data.hero} />
      <Learning data={data.learning} />
      <Languages data={data.languages} />
      <ExpertSection data={data.expertSection} />
      <CourseValue data={data.courseValue} />
    </div>
  );
};

export default NewNewProductPage;
