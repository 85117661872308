import { useParams } from "react-router";
import ParamsChecker from "../../ParamsChecker";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import NewNavbar from "../newNavbar/NewNavbar";
import { ProductDiv } from "./ProductWrapperCSS";

export function ProductWrapper({ children, footerData, navbarData }) {
  const { lang } = useParams();

  console.log(footerData);

  return (
    <ProductDiv>
      <ParamsChecker />
      {[
        "cz",
        "cs",
        "hu",
        "sk",
        "en",
        "hr",
        "lt",
        "ro",
        "bg",
        "lv",
        "pl",
      ].includes(lang) ? (
        <NewNavbar data={navbarData} />
      ) : (
        <Navbar data={navbarData}></Navbar>
      )}
      {children}
      <Footer data={footerData}></Footer>
    </ProductDiv>
  );
}
